import 'normalize.css';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';

const WAITING_TIMEOUT = 30 * 1000
const REFRESH_TIMEOUT = 60 * 60 * 1000

const url = window.location.search
const params = new URLSearchParams(url)
const streamUrl = params.get('stream')
const DOM = {
  player: document.querySelector('#player'),
  video: document.querySelector('#player > video')
}
const playerRef = videojs(DOM.video, {
  autoplay: true,
  controls: false,
  responsive: true,
  fluid: true,
  muted: true,
  sources: [{
    src: streamUrl,
    type: 'application/x-mpegURL'
  }]
})

let state = 'DEFAULT'
let waitingTimer = 0
let waitingInterval = null

const handlePlaying = () => {
  state = 'PLAYING'

  DOM.player.classList.add('playing')
  DOM.player.classList.remove('loading')
  DOM.player.classList.remove('paused')
  
  clearInterval(waitingInterval)
  waitingTimer = 0

  // console.log('playing');
}

const handlePause = () => {
  state = 'PAUSE'

  DOM.player.classList.add('paused')
  DOM.player.classList.remove('playing')

  // console.log('pause');
}

const handleCanPlay = () => {
  DOM.player.classList.add('canplay')
}

const handleWaiting = () => {
  state = 'WAITING'

  DOM.player.classList.add('loading')
  // console.log('waiting');

  clearInterval(waitingInterval)
  waitingTimer = 0
  waitingInterval = setInterval(()=> {
    // console.log('waiting', waitingTimer);
    if (state === 'WAITING') {
      waitingTimer++
    } else {
      waitingTimer = 0
    }
    if (waitingTimer > (WAITING_TIMEOUT / 1000)) {
      window.location.reload()
    }
  }, 1000)
}

const handleClick = () => {
  if (DOM.video.paused) {
    DOM.video.play()
  } else {
    DOM.video.pause()
  }
}

DOM.player.addEventListener('click', handleClick)

playerRef.on('play', handlePlaying)
playerRef.on('playing', handlePlaying)
playerRef.on('pause', handlePause)
playerRef.on('canplay', handleCanPlay)
playerRef.on('waiting', handleWaiting)
playerRef.on('error', handleWaiting)

setTimeout(() => {
  window.location.reload()
}, REFRESH_TIMEOUT)

// console.log(url, params, streamUrl)
